<template>

  <div class="trips-new">
    <main-nav />
    <main-titlebar :showback="showBack" />

 
     <Form ref="form" @submit="createTrip" :validation-schema="schema" @invalid-submit="onInvalidSubmit">
      <div  class="trip-view-wrapper" style="display:block;">
        <Field name="origin_address"  v-model="origin_address"  type="hidden"/>
        <!-- <Field name="origin_location" id="origin_location" v-model="origin_location" type="hidden"  /> -->
        <Field name="destination_address" id="destination_address" v-model="destination_address" type="hidden"  />
        <!-- <Field name="destination_location" id="destination_location" v-model="destination_location" type="hidden"  />         -->
      <!-- Departure -->
      <div class="form-group">
        <!-- <label for="etd">Date & time</label> -->
        <Field name="etd" v-model="etd" type="datetime-local" class="form-input" placeholder="Departure" />
      </div>
      <div class="form-group">
        <ErrorMessage name="etd" class="error-feedback" />
      </div>
      <!-- Origin / start location -->
      <div class="form-group">
         <!-- <label for="origin">Pickup / origin</label> -->
        <GMapAutocomplete 
        id="origin"
        name="origin"
        v-model="origin"
        class="form-input"
        placeholder="Start / begin adres"
        @place_changed="setOrigin"
        >
        </GMapAutocomplete>
      </div>
      <div class="form-group">
        <ErrorMessage name="origin_address" class="error-feedback" />
      </div>
      <!-- Destination/ end location -->        
      <div class="form-group">
        <!-- <label for="destination">Dropoff / destination</label> -->
        <GMapAutocomplete 
        id="destination"
        name="destination"
        v-model="destination"
        class="form-input"
        placeholder="Bestemming / eind adres"
        @place_changed="setDestination"
        >
      </GMapAutocomplete>
      </div>
      <div class="form-group">
        <ErrorMessage name="destination_address" class="error-feedback" />
      </div>
      <!-- Type -->
      <!-- <div class="form-group">
        <label for="type">Type</label>
        <Field v-model="type" name="type" as="select" class="form-input">
          <option value="s">Oneway</option>
          <option value="r">Retour</option>
        </Field>
      </div> -->
      <div class="form-group">
        <ErrorMessage name="type" class="error-feedback" />
      </div>

      <!-- Passengers -->
      <div class="form-group">
        <div class="col-2 col-2-c">
          <div class="form-label">
            <label for="passengers">Passagiers</label>
          </div>
        </div>
        <div class="col-2 col-2-c">
          <Field name="passengers" as="select" class="form-input" value="1">
            <option value="1" selected>1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </Field>
        </div>
      </div>
      <div class="form-group">
        <ErrorMessage name="passengers" class="error-feedback" />
      </div>


      <!-- Luggage -->
      <div class="form-group">
        <div class="col-2 col-2-c">
          <div class="form-label">
            <label for="luggage"># Bagage/koffers</label>
          </div>
        </div>
        <div class="col-2 col-2-c">
          <Field name="luggage" as="select" class="form-input" value="0">
            <option value="0" selected>Geen</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
          </Field>

        </div>
      </div>
      <div class="form-group">
        <ErrorMessage name="luggage" class="error-feedback" />
      </div>


      <!-- Price -->
      <div class="form-group">
        <div class="col-2 col-2-c">
          <div class="form-label">
            <label for="price">Fixed price</label>
          </div>
        </div>
        <div class="col-2 col-2-c">
          <Field name="price" id="price" class="form-input" v-model="price"  type="text" placeholder="0,00"/>
        </div>
      </div>


      <!-- <div class="form-group" style="float:left:width:100%;height:300px;">test</div> -->

      <!-- Client -->
      <div class="form-group">
        <div class="col-2 col-2-c">
          <div class="form-label">
            <label for="client">Klant</label>
          </div>
        </div>
        <div class="col-2 col-2-c">
          <Field name="client" as="select" class="form-input" >
            <option value="" selected>Kies een klant</option>
                <option v-for="contact in myContacts" :key="contact.id" :value="contact.id">{{contact.name}}</option>
          </Field>


        </div>
      </div>
      <div class="form-group">
        <ErrorMessage name="client" class="error-feedback" />
      </div>

      <!-- VIP or special -->
      <div class="form-group">
        <div class="col-2 col-2-c">
           <div class="form-label textAlignCenter">
            <label for="triptype">Type</label>
            </div>
        </div>
        <div class="col-2 col-2-c">
          <!-- <Field name="triptype" id="triptype" class="form-input" v-model="triptype"  type="checkbox" placeholder=""/> -->

          <Field name="triptype" as="select" class="form-input" value="n">
            <option value="n" selected>Normaal</option>
            <option value="s">Speciaal vervoer</option>
            <option value="v">VIP</option>
          </Field>

        </div>
      </div>

      <!-- Note -->
      <div class="form-group">
          <Field name="note" as="textarea" v-model="note" class="form-input" placeholder="Opmerkingen en/of notities"></Field>
      </div>


      <div class="trip-view-row">
        <div class="trip-view-col">
          <button class="default-button" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span><i class="fa-solid fa-plus"></i>  Aanmaken</span>
          </button>
        </div>
      </div>

      
    </div>
        <!-- Controls -->
      <div class="form-group bottom-button" style="">


 
      
    
      </div>

      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {ref} from 'vue'

import TripService from "../../services/trip-service";
import ContactService from "../../services/contact-service";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";


export default {
  name: "trips-new",
  
  components: {
    Form,
    Field,
    ErrorMessage,
      'main-titlebar': require('@/components/titlebar.vue').default,
      'main-nav': require('@/components/mainmenu.vue').default,
  },
  data() {
    const etd = this.currentDateTime(1200000)
    const origin = ref('')
    const origin_address = ref('')
    const origin_location = ref('')
    const destination_address = ref('')
    const destination_location = ref('')
    const destination = ref('')
    const type = ref('')
    const luggage = ref('')
    const price = ref('')
    const client = ref('')
    const triptype = ref('')
    const passengers = ref('')
    const cform = ref('cform');
    const googleKey = process.env.VUE_APP_TRIPTRADER_GOOGLE_API_KEY;
    const schema = yup.object().shape({
      // luggage: yup.string().required("Type is required"),
      // price: yup.string().required("Price is required"),
      origin_address: yup.string().required("Start adres is verplicht"),
      destination_address: yup.string().required("Eind adres is verplicht")
    });

    var lastTrip = '';
    var note = '';
    var showBack = true;
    var myContacts = [];
    
    return {
      googleKey,
      loading: false,
      message: "",
      schema,
      etd,
      origin,
      destination,
      origin_address,
      destination_address,
      origin_location,
      destination_location,
      type,
      passengers,
      price,
      luggage,
      client,
      triptype,
      lastTrip,
      note,
      myContacts,
      cform,
      showBack
    };
  },
    mounted() {
    var targ = this;


     ContactService.getMyContacts().then(
      (response) => {
        console.log("ContactService.getMyContacts: data loaded from: " + JSON.stringify(response));
        targ.status = response.status;
        targ.myContacts = response.data;
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );


  

  },
  computed: {

  },
  created() {
    if (this.loggedIn()) {
      console.log("New trip: user loged in: " + this.loggedIn());
    }
  },
  methods: {
    setOrigin(targ) {
      this.setPlace(targ, "origin")
    },
    setDestination(targ) {
      this.setPlace(targ, "destination")
    },     
    setPlace(targ, field) {
      var address = targ.formatted_address
      var latlng = targ.geometry.location;
      var address_field = field + "_address"; 
      this[''+address_field] = address;
      console.log("setPlace:" + address_field + " | " + address + " | " + latlng);
    },

    createTrip(trip) {
      this.loading = true;
      console.log("createTrip>> trip:" + JSON.stringify(trip));
      this.lastTrip = trip;
      TripService.createTrip(trip).then(
        (response) => {
           console.log(JSON.stringify(response));
           if(response.status == "failed") {
            alert("createTrip>> Error: "+ response.message);
            this.$router.go();

            // this.$router.push("/trips/new");            
      

           } else {
             this.content = response.data;
            this.$router.push("/trips/my");
           }
        },
        (error) => {
          console.log("createTrip>> error:" + JSON.stringify(error));
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.$router.push("/trips/new");
        }
      );
           this.$refs.form.resetForm();

    },
    onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
  },
};


</script>